const cities = [
    'stmaximin',
    'carpentras',
    'goudargues',
    'pierrelatte',
    'saintremy',
    'saintremy-formation',
    'cavaillon',
    'rognac',
    'dev',
    'stmaximin-formation',
    'pelissanne-formation',
    'pelissanne',
    'fos',
    'fos-formation',
    'eyguieres',
    'portstlouis-formation',
    'tarascon',
    'cucuron',
    'chateauneuflesmartigues-formation'
];

/**
 * site
 */
export const site = window.location.hostname.split('.')[1]
    ? window.location.hostname.split('.')[0] === 'www'
        ? window.location.hostname.split('.')[1]
        : window.location.hostname.split('.')[0]
    : 'dev';

export const showNewDashboard = () => cities.includes(site);

/**
 * url
 */
export default `https://www.${site}.api.digitmarche.fr/api`;
