import React, { useState } from 'react';

import { Button } from '@material-ui/core';
import { TabControl, Dialog } from 'components';
import { useHistory } from 'react-router-dom';
import { Loader, Form } from './components';

const GenerateAot = props => {
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    return (
        <Dialog
            title="Génération des AOT"
            loading={loading}
            onClose={() => history.push('/merchants/all')}
            open
            action={
                <>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setStep(step + 1)}
                        disabled={loading || step > 0}>
                        Génerer
                    </Button>
                </>
            }>
            <TabControl value={step} index={0}>
                <Form />
            </TabControl>
            <TabControl value={step} index={1}>
                <Loader />
            </TabControl>
            <TabControl value={step} index={2}></TabControl>
        </Dialog>
    );
};

export default GenerateAot;
