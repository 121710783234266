import env from 'common/env';
import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField } from '@material-ui/core';

const Form = () => {
    const [merchants, setMerchants] = useState([]);
    const getData = () => {
        fetch(`${env}/domaine/get-domain-bill-for-creation`)
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                setMerchants(data.listOfMerchant);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                <Autocomplete
                    options={merchants}
                    //size="small"
                    autoHighlight
                    //onChange={this.handleMerchantList}
                    noOptionsText={'Aucun commerçant trouvé'}
                    getOptionLabel={option => option.raisonSociale}
                    getOptionSelected={(option, value) => option.id == value.id}
                    renderInput={params => (
                        <TextField
                            fullWidth
                            {...params}
                            label={'Commerçant'}
                            helperText={'Choisir un commerçant'}
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
            <Grid item>
                <TextField
                    fullWidth
                    label={'Article 1'}
                    variant="outlined"
                    defaultValue={
                        'Un emplacement fixe pour l’année 2024 de 11 mètres linéaires est attribué sur le marché forain hebdomadaire de la Ville de Tarascon à Monsieur VERDRON Camille pour la vente de légumes avec prise d’effet le 01 octobre 2024. Cet emplacement est situé place Verdun sous le numéro 36V sans électricité avec véhicule. Monsieur VERDRON Camille peut se prévaloir d’une ancienneté de 0 ans. Le montant de la redevance de cette occupation est de 132€ au Trimestre au tarif en vigueur.'
                    }
                    multiline
                    rows={7}
                    maxRows={7}
                />
            </Grid>
            <Grid item>
                <TextField
                    fullWidth
                    label={'Signature'}
                    variant="outlined"
                    defaultValue={
                        'Fait à Tarascon le 23 septembre 2024 - Pour le Maire et par délégation, - Guy LUPERINI - Le Conseiller Municipal - Délégué à l’Occupation du Domaine Public,'
                    }
                    multiline
                    rows={2}
                    maxRows={2}
                />
            </Grid>
        </Grid>
    );
};

export default Form;
